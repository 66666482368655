import React from 'react';
import { IoPaperPlaneOutline } from 'react-icons/io5';

function ContactPage() {
        //#TODO: contact form

  return (
    <article class="contact" data-page="contact">
      <header>
        <h2 class="h2 article-title">Contact</h2>
      </header>
    
      <section class="mapbox" data-mapbox>
        <figure>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23205.78617831966!2d-8.451652004573639!3d43.3618987367402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2e7cfcf174574d%3A0x6a47350d095cdfee!2zQSBDb3J1w7Fh!5e0!3m2!1sen!2ses!4v1731083620514!5m2!1sen!2ses"
            width="400" height="300" loading="lazy">
          </iframe>
        </figure>
      </section>
    
      <section class="contact-form">
        <h3 class="h3 form-title">Contact Form (not implemented yet)</h3>
    
        <form action="#" class="form" data-form>
    
          <div class="input-wrapper">
            <input type="text" name="fullname" class="form-input" placeholder="Full name" required data-form-input/>
    
            <input type="email" name="email" class="form-input" placeholder="Email address" required data-form-input/>
          </div>
    
          <textarea name="message" class="form-input" placeholder="Your Message" required data-form-input></textarea>
    
          <button class="form-btn" type="submit" disabled data-form-btn>
            <IoPaperPlaneOutline />
            <span>Send Message</span>
          </button>
    
        </form>
    
      </section>
    </article>
  );
    
}

export default ContactPage;
