import React from 'react';
import { IoEyeOutline } from 'react-icons/io5';

// Componente para cada proyecto individual
function ProjectItem({ href, imgSrc, altText, title, category }) {
  return (
    <li className="project-item active" data-filter-item data-category={category.toLowerCase()}>
      <a href={href} target="_blank" rel="noreferrer">
        <figure className="project-img">
          <div className="project-item-icon-box">
            <IoEyeOutline />
          </div>
          <img src={imgSrc} alt={altText} loading="lazy" />
        </figure>
        <h3 className="project-title">{title}</h3>
        <p className="project-category">{category}</p>
      </a>
    </li>
  );
}

export default ProjectItem;
