import React from 'react';
import ServiceItem from '../items/ServiceItem';

function AboutService() {
  return (
    <section className="service">
      <h3 className="h3 service-title">What I'm doing</h3>
      <ul className="service-list">
        <ServiceItem
          iconSrc="./media/icons/icon-design.svg"
          iconAlt="design icon"
          title="Web design"
          text="The most modern and high-quality design made at a professional level."
        />
        <ServiceItem
          iconSrc="./media/icons/icon-backend.svg"
          iconAlt="Web development icon"
          title="Backend"
          text="High-level backend development, optimized for performance and scalability."
        />
        <ServiceItem
          iconSrc="./media/icons/icon-frontend.svg"
          iconAlt="mobile app icon"
          title="Frontend"
          text="High-quality development of sites at the professional level."
        />
        <ServiceItem
          iconSrc="./media/icons/icon-database.svg"
          iconAlt="camera icon"
          title="Data bases"
          text="Development and optimization of secure, scalable, and high-performance databases."
        />
      </ul>
    </section>
  );
}

export default AboutService;
