import React, { useState } from 'react';
import { FaChevronDown, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { IoCalendarOutline, IoLocationOutline, IoMailOutline, IoPhonePortraitOutline } from 'react-icons/io5';

function Sidebar() {
  // Estado para manejar la visibilidad del contenido expandido
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  // Función para alternar la clase `active` en la barra lateral
  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  return (
    <aside className={`sidebar ${isSidebarActive ? 'active' : ''}`} data-sidebar>
      <div className="sidebar-info">
        <figure className="avatar-box">
          <img src="/media/avatar-1.png" alt="Ivan Garcia" width="80" />
        </figure>

        <div className="info-content">
          <h1 className="name" title="Ivan Garcia">Ivan Garcia</h1>
          <p className="title">Full Stack Developer</p>
        </div>

        {/* Botón para expandir la información adicional */}
        <button className="info_more-btn" data-sidebar-btn onClick={toggleSidebar}>
          <span>Show Contacts</span>
          <FaChevronDown />
        </button>
      </div>

      {/* Sección de información adicional */}
      <div className="sidebar-info_more">
        <div className="separator"></div>

        <ul className="contacts-list">
          <li className="contact-item">
            <div className="icon-box">
              <IoMailOutline />
            </div>
            <div className="contact-info">
              <p className="contact-title">Email</p>
              <a href="mailto:ivanggdev@gmail.com" className="contact-link">ivanggdev@gmail.com</a>
            </div>
          </li>
          <li className="contact-item">
            <div className="icon-box">
              <IoPhonePortraitOutline />
            </div>
            <div className="contact-info">
              <p className="contact-title">Phone</p>
              <a href="tel:+346971607933" className="contact-link">+34 6971607933</a>
            </div>
          </li>
          <li className="contact-item">
            <div className="icon-box">
              <IoCalendarOutline />
            </div>
            <div className="contact-info">
              <p className="contact-title">Birthday</p>
              <time dateTime="2002-01-11">11 January, 2002</time>
            </div>
          </li>
          <li className="contact-item">
            <div className="icon-box">
              <IoLocationOutline />
            </div>
            <div className="contact-info">
              <p className="contact-title">Location</p>
              <address>A Coruña, Spain</address>
            </div>
          </li>
        </ul>

        <div className="separator"></div>

        <ul className="social-list">
          <li className="social-item">
            <a href="https://www.linkedin.com/in/ivan-garcia-garcia" target='_blank' rel="noreferrer" className="social-link">
              <FaLinkedin />
            </a>
          </li>
          <li className="social-item">
            <a href="https://www.instagram.com/ivangg._/" target='_blank' rel="noreferrer" className="social-link">
              <FaInstagram />
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;
