import React from 'react';
import BlogPost from '../components/Blog/BlogPost';

function BlogPage() {
  const posts = [
    {
      title: "Welcome to my blog",
      category: "OnBoarding",
      date: "2024-11-08",
      imageSrc: "./media/blog/hello-world-banner.png",
      altText: "Welcome to my blog",
      text: "This is my first post on my blog. I hope you enjoy it!"
    }
  ];

  return (
    <article className="blog" data-page="blog">
      <header>
        <h2 className="h2 article-title">Blog</h2>
      </header>

      <section className="blog-posts">
        <ul className="blog-posts-list">
          {posts.map((post, index) => (
            <BlogPost
              key={index}
              title={post.title}
              category={post.category}
              date={post.date}
              imageSrc={post.imageSrc}
              altText={post.altText}
              text={post.text}
            />
          ))}
        </ul>
      </section>
    </article>
  );
}

export default BlogPage;
